import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import Swal from "sweetalert2";

export default function UploadModal(props) {
  const { isOpen, change, handleClose } = props;

  const [changes, setChanges] = useState(0);

  const [file, setFile] = useState(null);

  const submit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post(`https://as.galileo-tutor.com/project/drive`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      if (err.response.status === 500) {
        console.log("There was a problem with the server");
      } else {
        console.log(err.response.data.msg);
      }
    }
    Swal.fire({
      title: "อัพโหลดบทคัดย่อเสร็จสิ้น",
      icon: "success",
      confirmButtonText: "ตกลง",
    });


    handleClose();
  };


  console.log(changes)

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10 " onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 mt-20 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all">
                <a
                  className="fixed right-7 top-7 z-10 cursor-pointer rounded-full bg-white p-2 text-2xl text-gray-800 shadow-lg "
                  onClick={handleClose}
                >
                  <RxCross1 />
                </a>
                {/* Main Content */}
                <h1 className="text-4xl mt-3 mb-10 font-bold">ส่งบทคัดย่อ</h1>
                <form onSubmit={submit}>
                  <label className="text-2xl">
                    บทคัดย่อ <span className="text-slate-300">(.pdf)</span>
                  </label>
                  <br />
                  <input
                    className={`${
                      file === null ? null : "bg-slate-100 "
                    } cursor-pointer my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                    type="file"
                    required
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                      console.log(file);
                    }}
                    accept="application/pdf"
                  />
                  <footer className="flex justify-end">
                    <input
                      className={`cursor-pointer my-2 text-white rounded-xl bg-blue-500 py-3 px-5 text-xl duration-150 placeholder:text-xl `}
                      type="submit"
                      value="อัปโหลด"
                    />
                  </footer>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
