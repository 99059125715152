import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Swal from "sweetalert2";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function Register() {
  const location = useLocation();
  console.log(location.state);

  const [memberCount, setMemberCount] = useState(2);

  const [member1, setMember1] = useState("");
  const [member2, setMember2] = useState("");
  const [member3, setMember3] = useState("");
  const [member4, setMember4] = useState("");

  const [teacher1, setTeacher1] = useState("");
  const [teacher2, setTeacher2] = useState("");

  const [data, setData] = useState({
    project_number: "",
    project: "",
    subject: "",
    type: "",
    school: "",
    member1: "",
    member2: "",
    member3: "",
    teacher1: "",
    teacher2: "",
    teacher3: "",
    thaiFile: "",
    engFile: "",
  });

  const removeMember = (memberCount) => {
    if (memberCount === 3) {
      setMember3("");
    } else if (memberCount === 4) {
      setMember4("");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const dataObject = {
      project: data.project,
      project_number: location.state.project_number,
      subject: data.subject,
      type: data.type,
      school: location.state.school,
      member1: data.member1,
      member2: data.member2,
      member3: data.member3,
      teacher1: data.teacher1,
      teacher2: data.teacher2,
      teacher3: data.teacher3,
      thaiFile: data.thaiFile,
      engFile: data.engFile,
    };

    console.log(dataObject);

    if (data.thaiFile != null) {
      const formData = new FormData();
      formData.append("file", data.thaiFile);

      axios.post(`https://as.galileo-tutor.com/project/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
    if (data.engFile != null) {
      const formData = new FormData();
      formData.append("file", data.engFile);

      axios.post(`https://as.galileo-tutor.com/project/uploadeng`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
    console.log(data.engFile);

    axios
      .post(`https://as.galileo-tutor.com/project/create`, dataObject)
      .then((res) => {});

    Swal.fire({
      title: "ส่งแบบฟอร์มเสร็จสิ้น!",
      icon: "success",
      confirmButtonText: "ตกลง",
    });

    setData({
      project_number: "",
      project: "",
      subject: "",
      type: "",
      school: "",
      member1: "",
      member2: "",
      member3: "",
      teacher1: "",
      teacher2: "",
      teacher3: "",
      thaiFile: "",
      engFile: "",
    });
  };

  return (
    <div className="text-[#2a384a]">
      <Navbar />
      <div>
        <img src="./img/register.png" className=" xl:w-2/3 mx-auto pt-20" />
        <div className="bg-white p-7 sm:p-12 md:p-20  xl:mx-36 box-shadow rounded-3xl mt-[-50px] sm:mt-[-80px] xl:mt-[-120px] mb-20">
          <form className="rounded-2xl bg-white  lg:p-10" onSubmit={onSubmit}>
            <h1 className="my-8 text-3xl font-extrabold  sm:text-4xl">
              รายละเอียดโครงงาน
            </h1>
            <div className="m-5 inline-block">
              <label className="text-2xl">
                ชื่อโครงงาน<sup className="text-red-500">*</sup>
              </label>
              <br />
              <input
                className={`${
                  data.project === "" ? null : "bg-slate-100 "
                } my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                type="text"
                value={data.project}
                onChange={(e) => {
                  setData({ ...data, project: e.target.value });
                  console.log(data);
                }}
                required
              />
            </div>
            <div className="m-5 inline-block">
              <label className="text-2xl">
                ประเภท<sup className="text-red-500">*</sup>
              </label>
              <br />

              <select
                className={`${
                  data.type === "" ? null : "bg-slate-100 "
                } my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                onChange={(e) => setData({ ...data, type: e.target.value })}
                required
              >
                <option value="">กรุณาเลือก</option>
                <option value="Oral Thai">Oral Thai</option>
                <option value="Oral Eng">Oral Eng</option>
                <option value="Poster">Poster</option>
              </select>
            </div>
            <div className="m-5 inline-block">
              <label className="text-2xl">
                สาขาวิชา<sup className="text-red-500">*</sup>
              </label>
              <br />

              <select
                className={`${
                  data.subject === "" ? null : "bg-slate-100 "
                } my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                onChange={(e) => setData({ ...data, subject: e.target.value })}
                required
              >
                <option value="">กรุณาเลือก</option>
                <option value="คณิตศาสตร์">คณิตศาสตร์</option>
                <option value="เคมี">เคมี</option>
                <option value="ฟิสิกส์">ฟิสิกส์</option>
                <option value="ชีวะ">ชีวะ</option>
                <option value="คอมพิวเตอร์​">คอมพิวเตอร์​</option>
              </select>
            </div>
            <div className="m-5 inline-block">
              <label className="text-2xl">
                รหัสโครงงาน<sup className="text-red-500">*</sup>
              </label>
              <br />
              <input
                className={`bg-slate-100 my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                type="text"
                value={location.state.project_number}
                disabled
              />
            </div>
            <div className="m-5 inline-block">
              <label className="text-2xl">
                บทคัดย่อภาษาไทย <span className="text-slate-300">(.pdf .docx)</span>
                <sup className="text-red-500">*</sup>
              </label>
              <br />
              <input
                className={`${
                  data.thaiFile === "" ? null : "bg-slate-100 "
                } cursor-pointer my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                type="file"
                // value={data.thaiFile}
                onChange={(e) => {
                  setData({ ...data, thaiFile: e.target.files[0] });
                  console.log(data.thaiFile);
                }}
                accept="application/pdf, application/msword"
              />
            </div>
            <div className="m-5 inline-block">
              <label className="text-2xl">
                บทคัดย่อภาษาอังกฤษ{" "}
                <span className="text-slate-300">(.pdf .docx)</span>
                <sup className="text-red-500">*</sup>
              </label>
              <br />
              <input
                className={`${
                  data.engFile === "" ? null : "bg-slate-100 "
                } cursor-pointer my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                type="file"
                onChange={(e) => {
                  setData({ ...data, engFile: e.target.files[0] });
                  console.log(data);
                }}
                accept="application/pdf, application/msword"
              />
            </div>

            <h1 className="my-8 text-3xl font-extrabold  sm:text-4xl">
              สมาชิกทีม
            </h1>

            <div className="m-5 inline-block">
              <label className="text-2xl font-bold ">สมาชิกทีมคนที่ 1</label>
              <br />
              <br />
              <label className="text-2xl ">
                ชื่อ - นามสกุล<sup className="text-red-500">*</sup>
              </label>
              <br />
              <input
                className={`${
                  data.member1 === "" ? null : "bg-slate-100 "
                } my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                type="text"
                value={data.member1}
                onChange={(e) => {
                  setData({ ...data, member1: e.target.value });
                  console.log(data);
                }}
                required
              />
            </div>

            <div className="m-5 inline-block">
              <label className="text-2xl font-bold ">สมาชิกทีมคนที่ 2</label>
              <br />
              <br />
              <label className="text-2xl ">ชื่อ - นามสกุล</label>
              <br />
              <input
                className={`${
                  data.member2 === "" ? null : "bg-slate-100 "
                } my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                type="text"
                value={data.member2}
                onChange={(e) => {
                  setData({ ...data, member2: e.target.value });
                  console.log(data);
                }}
              />
            </div>
            <div className="m-5 inline-block">
              <label className="text-2xl font-bold ">สมาชิกทีมคนที่ 3</label>
              <br />
              <br />
              <label className="text-2xl ">ชื่อ - นามสกุล</label>
              <br />
              <input
                className={`${
                  data.member3 === "" ? null : "bg-slate-100 "
                } my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                type="text"
                value={data.member3}
                onChange={(e) => {
                  setData({ ...data, member3: e.target.value });
                  console.log(data);
                }}
              />
            </div>
            <div className="m-5 inline-block">
              <label className="text-2xl font-bold ">โรงเรียน</label>
              <br />

              <input
                className={` my-2 w-full bg-slate-100 rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                type="text"
                value={location.state.school}
                disabled
              />
            </div>
            {/* {memberCount >= 3 ? (
              <div className="m-5 inline-block">
                <label className="text-2xl font-bold ">สมาชิกทีมคนที่ 3</label>
                <br />
                <br />
                <label className="text-2xl ">
                  ชื่อ - นามสกุล<sup className="text-red-500">*</sup>
                </label>
                <br />
                <input
                  className={`${
                    member3 === "" ? null : "bg-slate-100 "
                  } my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                  type="text"
                  value={member3}
                  onChange={(e) => {
                    setMember3(e.target.value);
                    // setData({
                    //   ...data,
                    //   members: newMemberData.filter((str) => str !== ""),
                    // });
                    console.log(member3);
                  }}
                  required
                />
              </div>
            ) : null}

            {memberCount >= 4 ? (
              <div className="m-5 inline-block">
                <label className="text-2xl font-bold ">สมาชิกทีมคนที่ 4</label>
                <br />
                <br />
                <label className="text-2xl ">
                  ชื่อ - นามสกุล<sup className="text-red-500">*</sup>
                </label>
                <br />
                <input
                  className={`${
                    member4 === "" ? null : "bg-slate-100 "
                  } my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                  type="text"
                  value={member4}
                  onChange={(e) => {
                    setMember4(e.target.value);
                    // setData({
                    //   ...data,
                    //   members: newMemberData.filter((str) => str !== ""),
                    // });
                    console.log(member4);
                  }}
                  required
                />
              </div>
            ) : null}

            {memberCount >= 3 ? null : (
              <>
                <div className="m-5 inline-block">
                  <div
                    className={` my-2  font-bold  rounded-2xl border-2 border-dashed border-slate-200 p-5 w-full text-2xl text-slate-400 duration-150 placeholder:text-xl hover:bg-slate-100  cursor-pointer`}
                    onClick={() => setMemberCount(memberCount + 1)}
                  >
                    + &nbsp;&nbsp;เพิ่มสมาชิก
                  </div>
                </div>
              </>
            )}

            {memberCount >= 3 ? (
              <div className="m-5 inline-block">
                <div
                  className={` my-2  font-bold  rounded-2xl border-2 border-dashed border-slate-200 p-5 w-full text-2xl text-slate-400 duration-150 placeholder:text-xl hover:bg-slate-100  cursor-pointer`}
                  onClick={() => {
                    setMemberCount(memberCount - 1);
                    removeMember(memberCount);
                  }}
                >
                  - &nbsp;&nbsp;ลดสมาชิก
                </div>
              </div>
            ) : null} */}

            <br />
            <div className="">
              <div className="inline-block">
                <h1 className="my-8 text-3xl font-extrabold  sm:text-4xl">
                  ที่ปรึกษาคนที่ 1
                </h1>
                <div className="m-5 ">
                  <label className="text-2xl ">
                    ชื่อ - นามสกุล<sup className="text-red-500">*</sup>
                  </label>
                  <br />
                  <input
                    className={`${
                      data.teacher1 === "" ? null : "bg-slate-100 "
                    } my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                    type="text"
                    value={data.teacher1}
                    onChange={(e) => {
                      setData({ ...data, teacher1: e.target.value });
                      console.log(data);
                    }}
                    required
                  />
                </div>
              </div>
              <div className="inline-block">
                <h1 className="my-8 text-3xl font-extrabold  sm:text-4xl">
                  ที่ปรึกษาคนที่ 2
                </h1>
                <div className="m-5 ">
                  <label className="text-2xl ">ชื่อ - นามสกุล</label>
                  <br />
                  <input
                    className={`${
                      data.teacher2 === "" ? null : "bg-slate-100 "
                    } my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                    type="text"
                    value={data.teacher2}
                    onChange={(e) => {
                      setData({ ...data, teacher2: e.target.value });
                      console.log(data);
                    }}
                  />
                </div>
              </div>
              <div className="inline-block">
                <h1 className="my-8 text-3xl font-extrabold  sm:text-4xl">
                  ที่ปรึกษาพิเศษ
                </h1>
                <div className="m-5 ">
                  <label className="text-2xl ">ชื่อ - นามสกุล</label>
                  <br />
                  <input
                    className={`${
                      data.teacher3 === "" ? null : "bg-slate-100 "
                    } my-2 w-full rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                    type="text"
                    value={data.teacher3}
                    onChange={(e) => {
                      setData({ ...data, teacher3: e.target.value });
                      console.log(data);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 inline-block">
              {data.project === "" ||
              data.type === "" ||
              data.subject === "" ||
              data.thaiFile === "" ||
              data.member1 === "" ||
              data.teacher1 === "" ? (
                <input
                  className={` my-2 w-full rounded-2xl bg-blue-300 p-5 text-xl font-extrabold text-white `}
                  type="submit"
                  value="ส่งแบบฟอร์ม"
                  disabled
                />
              ) : (
                <input
                  className={` my-2 w-full cursor-pointer rounded-2xl bg-blue-500 p-5 text-xl font-extrabold text-white duration-150 ease-in-out hover:bg-blue-700`}
                  type="submit"
                  value="ส่งแบบฟอร์ม"
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
