import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import axios from "axios";

import { BiUpload } from "react-icons/bi";
import { HiDocumentText } from "react-icons/hi";
import UploadModal from "../components/UploadModal";

export default function Dashboard() {
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    axios
      .get("https://sheet.best/api/sheets/5594f5f5-4f4e-4a3a-9660-b4f2ae7df55b")
      .then((res) => {
        setData(res.data);
      });
  }, []);

  console.log(data);
  return (
    <>
      <Navbar />
      <div className="px-44 py-14">
        {/* <h1 className="text-7xl font-extrabold text-gray-800">โครงงานของคุณ</h1> */}
        <img src="./img/urproject.png" className="w-3/4 mx-auto" alt="" />
        {data?.map((item, idx) => (
          <div className="bg-white rounded-3xl box-shadow p-16 ">
            <div className="flex justify-between items-center">
              <div>
                <p className="bg-purple-500 text-white max-w-max text-xl  rounded-full py-1 px-3">
                  สาขาวิชา{item.สาขาวิชา}
                </p>
                <h1 className="text-4xl my-3 font-bold">{item.ชื่อโครงงาน}</h1>
              </div>
              <div>
                <p className="text-xl py-1 ">บทคัดย่อ</p>
                {item["บทคัดย่อ"] === "" || item["บทคัดย่อ"] === null  ? (
                  <button
                    className="border border-gray-700 my-3 rounded-xl px-4 py-2 ease-in-out duration-150 hover:bg-gray-700 hover:text-white text-2xl"
                    onClick={() => setIsOpen(true)}
                  >
                    <BiUpload className="inline mr-2" /> อัปโหลด
                  </button>
                ) : (
                  <>
                    <a href={item["บทคัดย่อ"]} target="_blank">
                      <button className="border border-gray-700 my-3 mr-3 rounded-xl p-4 ease-in-out duration-150 hover:bg-gray-700 hover:text-white text-2xl">
                        <HiDocumentText />
                      </button>
                    </a>
                    <button
                      className="border border-gray-700 my-3 rounded-xl py-4  px-4 ease-in-out duration-150 hover:bg-gray-700 hover:text-white text-2xl"
                      onClick={() => setIsOpen(true)}
                    >
                      <BiUpload className="inline mr-2" /> อัปโหลดใหม่
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="flex mt-6">
              <div className="mr-6">
                <p className="text-xl py-1 ">สมาชิกโครงงาน</p>

                <div className="bg-slate-100 p-4 inline-block mr-6 rounded-2xl">
                  <p className="text-2xl">{item["ชื่อ-สกุลนักเรียนคนที่ 1"]}</p>
                </div>
                {item["ชื่อ-สกุลนักเรียนคนที่ 2"] === "" ||
                item["ชื่อ-สกุลนักเรียนคนที่ 2"] === null ? null : (
                  <div className="bg-slate-100 p-4 inline-block mr-2 rounded-2xl">
                    <p className="text-2xl">
                      {item["ชื่อ-สกุลนักเรียนคนที่ 2"]}
                    </p>
                  </div>
                )}
                {item["ชื่อ-สกุลนักเรียนคนที่ 3"] === "" ||
                item["ชื่อ-สกุลนักเรียนคนที่ 3"] === null ? null : (
                  <div className="bg-slate-100 p-4 inline-block mr-2 rounded-2xl">
                    <p className="text-2xl">
                      {item["ชื่อ-สกุลนักเรียนคนที่ 3"]}
                    </p>
                  </div>
                )}
              </div>
              <div>
                <p className="text-xl py-1 ">ครูที่ปรึกษา</p>
                <div className="bg-slate-100 p-4 inline-block mr-6 rounded-2xl">
                  <p className="text-2xl">
                    {item["ชื่อ-สกุลที่ปรึกษาคนที่ 1"]}
                  </p>
                </div>
                {item["ชื่อ-สกุลที่ปรึกษาคนที่ 2"] === "" ||
                item["ชื่อ-สกุลที่ปรึกษาคนที่ 2"] === null ? null : (
                  <div className="bg-slate-100 p-4 inline-block mr-2 rounded-2xl">
                    <p className="text-2xl">
                      {item["ชื่อ-สกุลที่ปรึกษาคนที่ 2"]}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <UploadModal isOpen={isOpen} handleClose={() => setIsOpen(false)} />
    </>
  );
}
