import React, { useState } from "react";
import ChangingText from "./ChangingText";
import Modal from "./LoginModal";
import ScheduleModal from "./ScheduleModal";

export default function Hero() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  return (
    <>
      <div className="text-center h-screen flex justify-center items-center flex-col">
        <img
          src="./img/title.png"
          className="hidden md:block w-full max-w-7xl mx-auto"
          alt=""
        />

        <img
          src="./img/title-xs.png"
          className="sm:hidden w-full max-w-7xl mx-auto"
          alt=""
        />
        <img
          src="./img/title-sm.png"
          className="hidden sm:block md:hidden w-full max-w-7xl mx-auto"
          alt=""
        />

        <div className="flex mx-7 gap-4  sm:gap-8">
          <div
            onClick={() => setIsOpen(true)}
            className="bg-blue-500 p-2 mt-10 rounded-xl inline-block font-bold cursor-pointer ease-in-out duration-150 hover:bg-blue-600"
          >
            <p className="text-2xl  px-3 py-2 text-white">สมัครเลย!</p>
          </div>
          <div
            onClick={() => setIsScheduleOpen(true)}
            className="bg-white  p-2 mt-10 rounded-xl inline-block font-bold cursor-pointer ease-in-out duration-150 hover:bg-slate-300"
          >
            <p className="text-2xl  px-3 py-2 ">รายละเอียดกำหนดการ</p>
          </div>
        </div>

        {/* <h1 className="mt-10 font-bold text-4xl transition-all duration-300 mx-auto">
          งานแข่งขันโครงงาน
          <span className={'text-blue-500 font-bold'}><ChangingText /></span>
        </h1>
        <span className="text-gray-400 block font-bold text-2xl mt-6 ">23-25 กรกฎาคม 2566</span> */}
        <Modal isOpen={isOpen} handleClose={() => setIsOpen(false)} />
        <ScheduleModal
          isOpen={isScheduleOpen}
          handleClose={() => setIsScheduleOpen(false)}
        />
      </div>
    </>
  );
}
